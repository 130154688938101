.downloadsPage {
  padding-top: 2em;
  h1 {
    font-size: 45px;
    margin-bottom: 2em;
  }
  h2 {
    font-size: 35px;
    margin-bottom: 1.4em;
  }
  hr {
    opacity: 0.3;
    border: 0.75px solid #6f798e;
  }
  section {
    padding-top: 5em;
    margin-bottom: 5em;
  }
  .lead {
    font-size: 24px;
  }
  .sellBox {
    padding-top: 0;
    h2 {
      color: var(--fcc-blue);
      margin-bottom: 0.8em;
    }
    svg {
      margin-bottom: 1.3em;
    }
    ul > li {
      margin-bottom: 5px;
      line-height: 25px;
    }
    .boxAbstract {
      margin-bottom: 2.3em;
    }
    .boxImageIcon {
      margin-bottom: 2.3em;
      img {
        height: 60px;
      }
    }
  }
  .featureBox {
    width: 100%;
    margin: 0 5px 30px 5px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 25px 15px 15px 15px;
    svg {
      margin-bottom: 20px;
    }
    h3 {
      font-size: 21px;
      font-weight: 500;
      color: var(--fcc-blue);
    }
    .boxAbstract {
      height: 100px;
    }
    img {
      height: 55px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    h1 {
      font-size: 35px;
    }
    .lead {
      font-size: 20px;
    }
  }
  @media (max-width: 991px) {
    padding-top: 1em;
    h1 {
      font-size: 25px;
    }
    h2 {
      font-size: 25px;
    }
    .lead {
      font-size: 18px;
    }
    section {
      padding-top: 3em;
      margin-bottom: 3em;
    }
    .sellBox {
      .boxImage {
        padding: 0 1em;
      }
    }
    .featureBox {
      .boxAbstract {
        height: unset;
      }
    }
  }
}
