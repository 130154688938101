.yourImpactPage {
  h1 {
    font-size: 40px;
    font-weight: 700;
    color: var(--fcc-blue);
    margin-bottom: 0.8em;
  }
  h2 {
    text-align: center;
    color: var(--fcc-blue);
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 0.8em;
  }
  h3 {
    margin-top: 0;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 4em;
    color: var(--fcc-blue);
    line-height: 28px;
  }
  section {
    margin-bottom: 5em;
  }
  p.lead {
    font-size: 20px;
    color: var(--fcc-blue);
    line-height: 28px;
  }
  .featureBox {
    font-size: 16px;
    background-color: #F9FAFA;
    border-radius: 12px;
    padding: 20px 24px;
    margin: 0 5px 20px 5px;
    img {
      border-radius: 4px;
      margin-bottom: 20px;
    }
    .featureText {
      color: #172B4D;
      text-align: center;
    }
  }
  .makingADifference {
    background-color: var(--fcc-blue);
    margin-bottom: 0;
    padding: 4em 0;
    h2 {
      color: var(--fcc-light-blue);
    }
    h3 {
      color: var(--fcc-light-blue);
    }
  }

  @media (max-width: 991px) {
    h1 {
      font-size: 30px;
      text-align: center;
    }
    h2 {
      font-size: 30px;
    }
    section {
      margin-bottom: 3em;
    }
    .ourCommitment {
      h3 {
        margin-bottom: 2em;
      }
    }
  }
}
