.homePage {
  --homepage-gray: #8993a4;
  --homepage-light-blue: #eff9fe;

  .header {
    background-color: var(--homepage-light-blue);
    margin-top: -30px;
    .container {
      position: relative;
      padding: 5em 15px;
    }
    h1 {
      font-size: 3em;
      color: var(--fcc-blue);
    }
    .lead {
      font-size: 22px;
      font-weight: 400;
      line-height: 32px;
      color: var(--fcc-gray);
    }
    .orange {
      color: #f8921a;
      font-weight: 700;
    }
    .loginBox {
      text-align: center;
      position: relative;
      padding: 40px 40px 30px 40px;
      background-color: white;
      border-radius: 7px;
      border: 1px solid #ddd;
      box-shadow: 0 0 13px rgba(0, 0, 0, 0.15);
      h2 {
        font-size: 28px;
        color: var(--fcc-blue);
        margin-bottom: 1.3em;
      }
      .hr-text {
        margin-bottom: 1em;
      }
      /* input {
        border-color: #ddd;
      } */
      input::placeholder {
        color: #ccc;
      }
      .btn-social {
        padding: 9px;
        display: block;
      }
      .btn-social.btn-google {
        border: 1px solid #ddd;
        background-color: white;
      }
      .btn-social.btn-apple {
        background-color: black;
        color: white;
      }
      .google-icon {
        margin-bottom: -5px;
        margin-right: 5px;
      }
      .fa-apple {
        margin-right: 5px;
      }
      .svg_wrapper {
        border-radius: 50%;
        border: 1px solid #afafaf;
        background: #fff;
        width: 45px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 5px;
      }
    }
  }
  .subHeader {
    h2 {
      font-size: 35px;
      color: var(--fcc-blue);
    }
    .container {
      position: relative;
      padding: 5em 15px;
    }
    p {
      font-size: 18px;
      margin-bottom: 1.3rem;
    }
    .featureBox {
      padding: 2em 0;
      border-radius: 10px;
      background-color: white;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
      text-align: center;
      margin-bottom: 2em;
      img {
        margin-bottom: 1.5em;
        height: 40px;
      }
      strong {
        color: var(--fcc-blue);
      }
    }
  }
  .videoConferencing {
    .container {
      position: relative;
      padding: 5em 15px;
    }
    h2 {
      font-size: 35px;
      color: white;
    }
    p {
      font-size: 18px;
      color: white;
    }
    .featureBox {
      display: block;
      margin-bottom: 1em;
      img {
        float: left;
        display: block;
        height: 60px;
      }
      .featureText {
        margin-left: 80px;
        display: block;
        font-size: 20px;
        min-height: 60px;
        color: var(--fcc-gray);
      }
    }
  }
  .conferencingApps {
    .container {
      position: relative;
      padding: 5em 15px;
    }
    h2 {
      font-size: 35px;
      color: var(--fcc-gray);
    }
    p {
      font-size: 18px;
      color: #8993a4;
    }
    .app-store {
      .app-store-btn {
        margin-right: 15px;
        img {
          width: auto;
          max-width: none;
          height: 55px;
        }
      }
    }
  }
  .internationalConferencing {
    position: relative;
    background-color: #eff9fe;
    .container {
      position: relative;
      padding: 5em 15px;
    }
    p {
      font-size: 18px;
      color: #8993a4;
    }
    h2 {
      font-size: 30px;
      color: var(--fcc-gray);
    }
    h3 {
      font-size: 22px;
    }
  }
  .conferenceCallFeatures {
    text-align: center;
    h2 {
      font-size: 30px;
      color: var(--fcc-blue);
    }
    p {
      font-size: 18px;
      color: #8993a4;
      margin-bottom: 3em;
    }
    .features {
      margin-bottom: 2em;
      .featureBox {
        display: block;
        margin-bottom: 1em;
        img {
          float: left;
          display: block;
          height: 60px;
        }
        .featureText {
          margin-left: 80px;
          display: block;
          font-size: 17px;
          min-height: 60px;
          font-weight: 700;
          text-align: left;
          color: var(--fcc-blue);
          display: flex;
          align-items: center;
        }
      }
    }
  }
  .featuredIn {
    background-color: #172b4d;
    h2 {
      font-size: 38px;
      color: white;
      text-align: center;
    }
    img {
      margin: 30px auto;
      height: 52px;
    }
    .features {
      padding-top: 1em;
      padding-bottom: 3em;
    }
  }
  .howFree {
    text-align: center;
    h2 {
      font-size: 30px;
      color: var(--fcc-blue);
    }
    p {
      font-size: 18px;
      color: var(--homepage-gray);
    }
    .features {
      text-align: left;
    }
    .featureBox {
      display: block;
      margin-bottom: 2em;
      background-color: var(--homepage-light-blue);
      border-radius: 7px;
      padding: 15px;
      img {
        float: left;
        display: block;
        height: 88px;
      }
      h3 {
        color: var(--fcc-gray);
        font-size: 24px;
        font-weight: 500;
        margin: 0 0 0.5em 108px;
      }
      .featureText {
        margin-left: 108px;
        display: block;
        font-size: 15px;
        color: var(--fcc-gray);
      }
    }
  }
  .freeConferencing {
    h2 {
      font-size: 30px;
    }
    p {
      font-size: 18px;
      color: var(--homepage-gray);
    }
  }
  @media (min-width: 992px) {
    section {
      .container {
        position: relative;
        padding: 5em 15px;
      }
    }
    .header {
      .bottom-orange-circle {
        position: absolute;
        bottom: 0;
        left: -80px;
        height: 350px;
        width: 700px;
        border-radius: 350px 350px 0 0;
        background-color: #f8921a;
      }
      background: url(../images/Homepage-Header.webp) no-repeat center center /
        cover;
    }
    .subHeader {
      .top-orange-circle {
        z-index: -1;
        position: absolute;
        top: 0;
        left: -80px;
        height: 350px;
        width: 700px;
        border-radius: 0 0 350px 350px;
        background-color: #fff5e9;
      }
    }
    .videoConferencing {
      background-image: linear-gradient(to right, #172b4d 50%, white 50%);
      .left {
        padding-right: 4em;
      }
      .right {
        padding-left: 4em;
      }
    }
    .internationalConferencing {
      .background {
        position: absolute;
        width: 55%;
        top: 0;
        bottom: 0;
        right: 0;
        background: url("../images/Homepage-Background.webp") no-repeat left
          center;
      }
    }
    .howFree {
      p {
        margin-bottom: 3em;
      }
    }
  }
  @media (max-width: 991px) {
    .header {
      .container {
        padding: 3em 15px;
      }
      h1 {
        font-size: 32px;
      }
      .lead {
        font-size: 18px;
        line-height: 25px;
      }
      .info {
        margin-bottom: 1em;
      }
    }
    .subHeader {
      .container {
        padding: 3em 15px;
      }
      .left {
        margin-bottom: 3em;
      }
    }
    .videoConferencing {
      .container {
        padding: 0 15px;
      }
      .left {
        padding: 3em 25px 3em 25px;
        background-color: #172b4d;
      }
      .right {
        padding: 3em 25px 2em 25px;
      }
    }
    .conferencingApps {
      .container {
        padding: 3em 15px;
      }
      .left {
        margin-bottom: 3em;
      }
      .app-store {
        .app-store-btn {
          img {
            height: 48px;
          }
        }
      }
    }
    .internationalConferencing {
      .container {
        padding: 3em 15px;
      }
    }
    .conferenceCallFeatures {
      .container {
        padding: 3em 15px;
      }
    }
    .featuredIn {
      .container {
        padding: 4em 15px 3em 15px;
      }
      h2 {
        margin-bottom: 0.4em;
      }
      img {
        height: 40px;
        display: block;
      }
      .features {
        padding: 2em 0;
      }
    }
    .howFree {
      .container {
        padding: 3em 15px;
      }
    }
    .freeConferencing {
      .container {
        padding: 2em 15px 3em 15px;
      }
      .left {
        margin-bottom: 3em;
      }
    }
  }
}
