.featuresPage {
  --fcc-features-gray: #8993a4;

  h1 {
    font-size: 50px;
    color: var(--fcc-blue);
  }
  h2 {
    font-size: 40px;
    text-align: center;
    color: var(--fcc-blue);
  }
  h3 {
    text-align: center;
    font-size: 24px;
    line-height: 34px;
    font-weight: 500;
    color: var(--fcc-features-gray);
    margin-bottom: 1.4em;
  }
  section {
    padding-bottom: 5em;
  }
  .affixMenu {
    position: sticky;
    top: 100px;
    z-index: 10;
  }
  .affixItem {
    color: var(--fcc-blue) !important;
  }
  .affixActiveItem {
    color: var(--fcc-blue) !important;
    font-weight: 700;
  }
  .featureBox {
    width: 100%;
    margin-bottom: 2em;
    border-radius: 12px;
    background-color: #f9fafa;
    padding: 20px;
    text-align: left;
    img {
      margin-bottom: 0.5em;
    }
    h4 {
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 0.8em;
    }
    p {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
  .header {
    margin-bottom: 8em;
  }
  .downloads {
    .first {
      margin-bottom: 4em;
    }
    .features {
      margin-bottom: 2em;
    }
    .featureItem {
      margin-bottom: 15px;
      span {
        padding-left: 20px;
        font-size: 24px;
        color: var(--fcc-blue);
        font-weight: 500;
      }
    }
    .downloadsButton {
      margin-bottom: 3em;
    }
    .downloadsCalendar {
      margin-bottom: 1em;
    }
  }
  .upgrades {
    .upgradesBox {
      margin-bottom: 2em;
      padding: 20px 20px 40px 20px;
      position: relative;
      background-color: #2773a7;
      border-radius: 12px;
      color: white;
      font-size: 16px;
      h3 {
        color: white;
        font-size: 40px;
        font-weight: 700;
        margin-bottom: 0.5em;
      }
      .boxContent {
        padding-top: 70px;
      }
      .boxImage {
        padding-top: 30px;
      }
      .boxTag {
        position: absolute;
        right: 0;
        top: 15px;
        background-color: #f88517;
        color: white;
        font-weight: 700;
        font-size: 16px;
        border-radius: 23px 0 0 23px;
        padding: 7px 50px;
      }
    }
  }
  .bundles {
    .bundleBox {
      margin-bottom: 2em;
      img {
        border-radius: 12px;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
      }
      h4 {
        font-size: 20px;
        margin: 15px 0;
      }
    }
  }
  @media (max-width: 991px) {
    h2 {
      font-size: 35px;
    }
    section {
      padding-bottom: 3em;
    }
    .header {
      margin-bottom: 3em;
      h1 {
        font-size: 30px;
      }
    }
    .downloads {
      .first {
        margin-bottom: 2em;
      }
    }
    .upgrades {
      .upgradesBox {
        padding-top: 40px;
        padding-bottom: 20px;
        .boxContent {
          padding-top: 30px;
        }
        .boxImage {
          text-align: center;
        }
        .boxTag {
          text-align: center;
          top: -1px;
          left: 0;
          border-radius: 12px 12px 0 0;
        }
      }
    }
  }
}
