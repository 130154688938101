.tableBox {
  border-radius: 32px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  thead {
    tr {
      th {
        border-bottom: 0;
      }
    }
  }
  .blueBackground {
    background-color: var(--fcc-pricing-light-blue);
  }
  .tableTitle {
    padding: 20px;
    font-size: 20px;
    color: var(--fcc-blue);
  }
  tbody {
    border-top: none !important;
    tr {
      td {
        padding: 14px 20px;
        border: none;
        text-align: center;
      }
      th {
        padding: 14px 20px;
        color: #181E28;
        border: none;
        .info {
          padding-left: 10px;
        }
      }
    }
  }
}
.comparePlans {
  .info {
    :hover {
      cursor: pointer;
    }
  }
  h3 {
    margin-bottom: 5px;
  }
  h4 {
    margin: 0 0 15px 0;
    color: var(--fcc-pricing-gray);
    text-align: center;
    font-size: 20px;
  }
}

.competition {
  h4 {
    font-size: 20px;
  }
}
