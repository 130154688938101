.pricingPage {
  --fcc-pricing-orange: #f8921a;
  --fcc-pricing-light-gray: #f3f4f6;
  --fcc-pricing-light-blue: #eff9fe;
  --fcc-pricing-green: #56ad0c;
  --fcc-pricing-gray: #8993a4;

  h1 {
    text-align: center;
    font-size: 50px;
    font-weight: 700;
    color: var(--fcc-pricing-orange);
  }
  h2 {
    text-align: center;
    margin-top: 0;
    color: var(--fcc-blue);
    font-size: 40px;
    font-weight: 700;
  }
  h3 {
    text-align: center;
    margin-top: 0;
    margin-bottom: 1.5em;
    font-size: 24px;
    font-weight: 500;
    color: var(--fcc-pricing-gray);
  }
  section {
    margin-bottom: 5em;
  }
  .buttonContainer {
    padding: 20px;
  }
  .header {
    padding-top: 1em;
    text-align: center;
    margin-bottom: 5em;
    p {
      font-size: 16px;
      color: var(--fcc-blue);
      font-weight: 700;
      margin-bottom: 15px;
    }
    .togglePeriod {
      border-radius: 90px;
      background-color: var(--fcc-pricing-light-gray);
      label {
        font-size: 14px;
        color: var(--fcc-blue);
        height: 53px;
        width: 190px;
        border-radius: 90px !important;
        background-color: var(--fcc-pricing-light-gray);
        box-shadow: none !important;
      }
      .active {
        box-shadow: none;
        color: white;
        background-color: var(--fcc-pricing-green);
      }
      .annual {
        padding: 5px 10px;
        strong {
          font-weight: 700;
          display: block;
        }
      }
      .monthly {
        padding: 15px 30px;
      }
    }
  }
  .plans {
    .featureBox {
      position: relative;
      border-radius: 8px;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
      padding: 15px 15px 85px 15px;
      height: 630px;
      margin: 0 5px 30px 5px;
      h4 {
        color: #333366;
        font-size: 24px;
        margin: 0 0 20px 0;
        .boxPrice {
          padding-top: 7px;
          font-size: 15px;
          font-weight: 700;
          color: #f88517;
          float: right;
        }
      }
      h5 {
        font-size: 20px;
        color: var(--fcc-blue);
      }
      .boxHeader {
        height: 40px;
      }
      .labelBlue {
        background-color: #2773a7;
        color: #e9f7ff;
      }
      .labelOrange {
        background-color: #f8921a;
        color: white;
      }
      .boxLabel {
        padding: 8px;
        border-radius: 8px;
        font-size: 12px;
        font-weight: 700;
      }
      .boxAbstract {
        font-size: 14px;
        color: var(--fcc-pricing-gray);
        height: 80px;
      }
      .boxFeatures {
        color: var(--fcc-pricing-gray);
      }
      .boxFooter {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        .boxFooterLink {
          width: 100%;
          text-align: center;
          display: block;
          padding: 0;
          font-size: 16px;
          border-radius: 0 0 8px 8px;
          background-color: var(--fcc-pricing-light-blue);
          color: #2773a7;
          font-weight: 700;
          height: 60px;
          line-height: 60px;
        }
      }
    }
  }
  .oneNumber {
    h2 {
      margin-bottom: 0.3em;
    }
    .price {
      font-weight: 700;
      text-align: center;
      margin-bottom: 2em;
      color: var(--fcc-pricing-gray);
    }
    .featureBox {
      background-color: var(--fcc-light-gray);
      border-radius: 12px;
      padding: 20px;
      margin-bottom: 2em;
      ul {
        margin-bottom: 0;
      }
      img {
        float: left;
        width: 60px;
      }
      .boxContent {
        margin-left: 75px;
      }
      h4 {
        margin: 0 0 15px 0;
        font-size: 20px;
        font-weight: 700;
      }
    }
  }
  .fccPro {
    background-color: var(--fcc-blue);
    padding: 5em 0;
    h2 {
      margin-bottom: 0.3em;
      color: var(--fcc-pricing-light-blue);
    }
    h3 {
      color: var(--fcc-pricing-light-blue);
    }
    .price {
      font-weight: 700;
      text-align: center;
      margin-bottom: 2em;
      color: var(--fcc-pricing-light-blue);
    }
    .featureBox {
      background-color: var(--fcc-light-gray);
      padding: 20px;
      width: 100%;
      border-radius: 12px;
      margin-bottom: 2em;
      color: var(--fcc-gray);
      img {
        margin-bottom: 10px;
      }
      h4 {
        margin: 0 0 15px 0;
        font-size: 20px;
        font-weight: 700;
      }
    }
  }
  @media (max-width: 991px) {
    h1 {
      font-size: 40px;
    }
    h2 {
      font-size: 30px;
    }
    h3 {
      font-size: 20px;
    }
    section {
      margin-bottom: 3em;
    }
    .buttonContainer {
      padding: 20px 0;
    }
    .header {
      .togglePeriod {
        label {
          width: 150px;
        }
      }
    }
    .plans {
      .featureBox {
        height: unset;
        .boxAbstract {
          height: unset;
          margin-bottom: 20px;
        }
        .boxFeatures {
          display: none;
        }
      }
    }
  }
}
