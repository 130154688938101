:root {
  --fcc-screen-md-min: 992px;
  --fcc-screen-md-max: 1199px;
  --fcc-screen-sm-min: 768px;
  --fcc-screen-sm-max: 991px;
  --fcc-dark-gray: #4d4d4d;
  --fcc-gray: #172b4d;
  --fcc-light-gray: #f9fafa;
  --fcc-orange: #f58517;
  --fcc-orange-hover: #eb7a0a;
  --fcc-blue: #2773a7;
  --fcc-blue-hover: #2b7eb6;
  --fcc-light-blue: #e9f7ff;
  --fcc-light-blue-1: #eff9fe;
  --fcc-green: #57ad0e;
  --fcc-green-hover: #60bd0f;
  --twitter-color: #21abf4;
  --youtube-color: #cc181e;
  --linkedin-color: #0282bd;
}

@font-face {
  font-family: "Open Sans";
  src: url("./fonts/OpenSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("./fonts/OpenSans-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans";
  src: url("./fonts/OpenSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("./fonts/OpenSans-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans";
  src: url("./fonts/OpenSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("./fonts/OpenSans-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans";
  src: url("./fonts/OpenSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("./fonts/OpenSans-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

h1 {
  margin-bottom: 0.8em;
  font-weight: 700;
}

h2 {
  margin-bottom: 0.8em;
  font-weight: 700;
}

h3 {
  margin-bottom: 0.8em;
  font-weight: 700;
}

h4 {
  font-weight: 700;
  line-height: 1.2;
}

h5 {
  font-weight: 700;
  line-height: 1.2;
}

p {
  margin-bottom: 1.3em;
}

.jumptarget {
  display: block;
  position: relative;
  top: -100px;
  visibility: hidden;
}

.fcc-blue {
  color: var(--fcc-blue);
}

.fcc-orange {
  color: var(--fcc-orange);
}

.text-justify {
  text-align: justify;
}

.text-left {
  text-align: left;
}

body {
  margin: 0;
  margin-top: 80px;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.45;
  color: var(--fcc-dark-gray);
}

a,
a:hover {
  color: var(--fcc-blue);
}

.btn.btn-link {
  color: var(--fcc-blue);
}

nav.navbar.main-navigation {
  height: 50px;
  padding: 0 10px;
  border-bottom: 1px solid rgba(137, 147, 164, 0.5);
  background-color: white;
}
.main-navigation .nav-link {
  padding-top: 14px;
  height: 48px;
  margin: 0 5px;
}

.main-navigation .btn {
  margin: 6px 0;
}

.main-navigation .dropdown-menu {
  margin-top: 0;
  border-radius: 0 1px 1px 0;
  box-shadow: 0 20px 21px 3px rgba(0, 0, 0, 0.1);
  border: none;
  border-top: 1px solid #ccc;
}

.main-navigation .dropdown-item {
  padding: 0.5rem 1rem;
}

.profile-menu .dropdown-menu .profile-item:hover {
  background-color: white;
}

.profile-menu .nav-link {
  margin-right: 15px;
  height: 48px;
  color: rgba(0, 0, 0, 0.55);
}

profile-menu .nav-link:hover {
  color: rgba(0, 0, 0, 0);
}

.profile-menu .nav-link img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: -7px;
}

nav.navbar .btn {
  font-size: 14px;
}

nav.navbar .btn-outline-light {
  color: var(--fcc-blue);
  border-color: #eee;
}

nav.navbar .btn-light {
  color: #777;
  background-color: #eee;
  border-color: #eee;
}

nav.navbar .btn-light:hover,
nav.navbar .btn-light:active,
nav.navbar .btn-light:focus {
  color: black;
  background-color: #ddd;
  border-color: #ddd;
}

.main-dropdown {
  background-color: white;
  margin: 0 -9px;
  /* border-bottom: 1px solid #ccc; */
}

.form-check-input {
  transform: scale(1.2);
}

.form-check-label {
  margin-left: 5px;
}

.form-control-lg {
  padding: 0.65rem 0.9rem;
  font-size: 1rem;
}

.btn:disabled {
  opacity: .65;
}

.btn-lg {
  padding: 0.5rem 0.9rem;
  font-size: 1.15rem;
}

.btn-primary, .btn-primary:disabled {
  background-color: var(--fcc-blue);
}

.btn-primary:hover,
.btn-primary:focus {
  background-color: var(--fcc-blue-hover);
}

.btn-outline-primary {
  color: var(--fcc-blue);
  outline: var(--fcc-blue) solid 2px;
  outline-offset: -2px;
  background-color: white;
}

.btn-outline-primary:hover,
.btn-outline-primary:active,
.btn-outline-primary:focus {
  background-color: var(--fcc-light-gray);
  color: var(--fcc-blue);
}

.btn-orange {
  background-color: var(--fcc-orange);
  color: white;
}

.btn-orange:hover,
.btn-orange:active,
.btn-orange:focus {
  background-color: var(--fcc-orange-hover);
  color: white;
}

.btn-outline-orange {
  color: var(--fcc-orange);
  outline: var(--fcc-orange) solid 2px;
  outline-offset: -2px;
  background-color: #f3f4f6;
}

.btn-outline-orange:hover,
.btn-outline-orange:active,
.btn-outline-orange:focus {
  background-color: white;
  color: var(--fcc-orange);
}

.btn-green {
  background-color: var(--fcc-green);
  color: white;
}

.btn-green:hover,
.btn-green:active,
.btn-green:focus {
  background-color: var(--fcc-green-hover);
  color: white;
}

.btn-white {
  background-color: white;
  color: #333;
}

.btn-white:hover,
.btn-white:active,
.btn-white:focus {
  background-color: #dedede;
  color: black;
}

.modal-fcc {
  max-width: 576px;
}

.modal-fcc .modal-body {
  padding-top: 1.5rem !important;
}

footer {
  border-top: 1px solid #ccc;
  background-color: #fafafa;
  padding-top: 25px;
}

footer h3 {
  text-align: left;
  margin-bottom: 0.8em;
  color: #555;
  font-size: 1.333em;
  font-weight: 700;
}

footer ul {
  list-style-type: none;
  width: 100%;
  margin: 0;
  padding: 0;
}

footer ul > li {
  padding: 5px 0;
}

footer ul > li > a {
  color: var(--fcc-blue);
  text-decoration: none;
  line-height: 1.75;
}

footer ul > li > a:hover {
  color: var(--fcc-blue);
  text-decoration: underline;
}

footer ul > li > button.btn.btn-link {
  padding: 0;
  text-decoration: none;
}

footer ul > li > button.btn.btn-link:hover {
  text-decoration: underline;
}

footer .social-icons {
  padding: 6px 0;
}

footer .social-icons a {
  margin: 7px;
}

footer .social-icons a.twitter svg {
  fill: var(--twitter-color);
}

footer .social-icons a.youtube svg {
  fill: var(--youtube-color);
}

footer .social-icons a.linkedin svg {
  fill: var(--linkedin-color);
}

footer .social-icons a svg {
  height: 33px;
  width: 33px;
}

footer #sitelegend ul {
  list-style-type: none;
  text-align: center;
  margin: 0;
  padding: 0;
}

footer #sitelegend > ul > li {
  display: inline-block;
  padding: 10px;
}

footer #company-info {
  color: #777;
}

footer hr {
  border-top: 1px solid #999;
}

.svg_wrapper {
  border-radius: 50%;
  border: 1px solid #afafaf;
  background: #fff;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

@media (max-width: 991px) {
  .main-navigation {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
  .main-dropdown {
    padding: 0 10px;
    margin-top: 1px !important;
    border-bottom: 1px solid #ccc;
  }
  .main-dropdown .dropdown-divider {
    margin: 0 -10px;
  }
  .profile-menu {
    margin-top: -1px;
  }
  .navbar-toggler {
    margin-top: -2px;
  }
  footer {
    text-align: center;
  }

  footer #footernav h3 {
    text-align: center;
  }

  footer #sitelegend ul > li {
    display: block;
  }

  footer #footernav h3 {
    margin-top: 1.414em;
  }

  footer #footernav ul li {
    padding: 12px 0;
  }

  footer .social-icons {
    padding: 16px 0;
  }

  footer .social-icons a {
    margin: 16px;
  }
}

@media (max-width: 991px) {
  .btn-lg {
    padding: 0.4rem 0.9rem;
    font-size: 1.1rem;
  }
}

@media (max-width: 1199px) {
  .navbar-brand {
    width: 37px;
    overflow: hidden;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 900px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}
