.upgradesPage {
  .header {
    margin-top: -30px;
    background-color: #f3f4f6;
    color: var(--fcc-gray);
  }
  .lead {
    margin-bottom: 20px;
    font-weight: 300;
    line-height: 1.4;
    font-size: 21px;
    text-align: justify;
  }
  .togglePeriod {
    margin-bottom: 2em;
    border-radius: 5px;
    background-color: var(--fcc-light-blue);
    border: 2px solid var(--fcc-light-blue);
    label {
      font-size: 14px;
      color: #c4c9d1;
      font-weight: 700;
      border-radius: 5px;
      background-color: var(--fcc-light-blue);
      box-shadow: none !important;
    }
    .active {
      color: var(--fcc-blue);
      background-color: white;
    }
  }
  h2 {
    font-size: 35px;
  }
  .blueBadge {
    padding: 6px 10px;
    background-color: #337ab7 !important;
  }
  .orangeBadge {
    padding: 6px 10px;
    background-color: var(--fcc-orange) !important;
  }
  .bundleSave {
    text-align: center;
    padding-bottom: 0;
    h2 {
      color: var(--fcc-blue);
    }
    .lead {
      text-align: center;
      big {
        color: var(--fcc-orange);
        font-weight: 700;
        font-size: 1.7em;
        white-space: nowrap;
      }
    }
  }
  .fccPro {
    ul {
      padding-left: 0;
      list-style: none;
      margin-bottom: 2em;
    }
    li {
      background: url("../images/Green-Circle-Check.png") no-repeat 0 3px;
      padding-left: 2em;
      margin-bottom: 1em;
    }
    .featureBox {
      border-radius: 7px;
      padding: 32px 16px;
      background-color: var(--fcc-light-gray);
      margin-bottom: 2em;
      img {
        margin-bottom: 1.5em;
      }
      h4 {
        font-size: 18px;
        margin-bottom: 1em;
      }
    }
  }
  .oneNumber {
    big {
      font-weight: 700;
      color: var(--fcc-orange);
      white-space: nowrap;
    }
    h2 {
      color: var(--fcc-blue);
    }
    ul {
      margin-bottom: 3em;
    }
  }
  .freeFeatures {
    .featuresBox {
      padding: 2.5em 2em 2em 2em;
      border-radius: 7px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
      h4 {
        margin-bottom: 1em;
      }
      ul {
        margin-bottom: 2em;
        padding-left: 0;
        list-style: none;
      }
      li {
        background: url("../images/Green-Circle-Check.png") no-repeat 0 0;
        padding-left: 2em;
        margin-bottom: 0.5em;
      }
    }
  }
  .personalizedMeeting {
    padding-bottom: 3em;
    h4 {
      margin-bottom: 1.2em;
    }
    .card {
      margin-bottom: 3em;
      img {
        border-radius: 7px 7px 0 0;
      }
      .cardBody {
        background-color: var(--fcc-light-gray);
        padding: 1.5em;
        border-radius: 0 0 7px 7px;
        h4 {
          margin-top: 0;
          margin-bottom: 0.8em;
        }
      }
    }
  }
  .extraStorage {
    text-align: center;
    padding: 3em 0;
    background-color: var(--fcc-light-blue-1);
    .storageItem {
      cursor: pointer;
      background-color: #172b4d;
      color: white;
      padding: 0.7em;
      border-radius: 40px/50px;
      margin-bottom: 1em;
      .storagePrice {
        font-weight: bold;
        margin-bottom: 0.3em;
      }
      .storageAmount {
        font-size: 1.5em;
        font-weight: bold;
      }
      &.active {
        background-color: var(--fcc-orange);
      }
    }
  }
  section {
    padding: 5em 0;
  }
  @media (max-width: 991px) {
    .header {
      padding: 3em 0;
      button {
        margin-bottom: 1em;
      }
    }
    .lead {
      font-size: 18px;
    }
    .bundleSave {
      .lead {
        big {
          font-size: 1.3em;
        }
      }
    }
    .fccPro {
      padding-bottom: 2em;
    }
    .oneNumber {
      padding-bottom: 2em;
      img {
        margin-bottom: 3em;
      }
    }
    .freeFeatures {
      padding-bottom: 2em;
      .featuresBox {
        margin-top: 2em;
      }
    }
  }
}
