.noLimits {
  padding: 3em;
  background-color: white;
  border-radius: 7px;
  img {
    max-height: 60px;
  }
  h2 {
    margin-top: 5px;
  }
  hr {
    margin: 30px 0 40px 0;
  }
  @media (max-width: 991px) {
    padding: 1.5em;
    h2 {
      font-size: 1.4em !important;
    }
  }
}
