.pwycPage {
  h1 {
    color: white;
  }
  .sellBox {
    margin-top: -30px;
    padding: 5em 0;
    background: url("../images/PWYC Header.webp") 50% 50% no-repeat !important;
    .lead {
      font-size: 21px;
      color: white;
    }
    .sliderBox {
      padding: 20px;
      background-color: white;
      border-radius: 7px;
      img {
        max-width: 160px;
        margin-bottom: 40px;
      }
    }
    .sliderContainer {
      width: 100%;
      margin-bottom: 25px;
    }
    .donationAmount {
      text-align: center;
      padding: 10px;
      background-color: #f3f4f6;
      font-size: 17px;
      font-weight: 700;
      border-radius: 7px;
      margin-bottom: 50px;
    }
    .slider::-webkit-slider-runnable-track {
      background-color: transparent;
    }
    .slider::-moz-range-track {
      background-color: transparent;
    }
    .slider {
      background: #e7e9ed;
      border-radius: 8px;
      height: 8px;
      width: 100%;
      outline: none;
      appearance: none;
      -webkit-appearance: none;
    }
    .slider::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: #f8921a;
      cursor: pointer;
      margin-top: -6px;
    }
    .slider::-moz-range-thumb {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: #f8921a;
      border: none;
      cursor: pointer;
      margin-top: -6px;
    }
    .featureBox {
      width: 100%;
      font-weight: 600;
      font-size: 15px;
      margin-bottom: 1em;
      padding: 7px;
      background-color: white;
      opacity: 0.3;
      border-radius: 7px;
      color: black;
      &.active {
        opacity: 10;
      }
      .featureState {
        display: block;
      }
      img {
        float: left;
        display: block;
        width: 44px;
      }
      .featureText {
        margin-left: 50px;
        display: block;
      }
    }
  }
  section {
    padding-top: 5em;
    margin-bottom: 5em;
  }
  .lead {
    margin-bottom: 20px;
    font-size: 21px;
    line-height: 1.4;
  }
  .freeFeatures {
    background-color: #f3f4f6;
    border-radius: 7px;
    padding: 3em;
    h2.large {
      font-size: 3em;
      margin-top: 1em;
      margin-right: 1em;
    }
  }
  .paidFeatures {
    margin-bottom: 8em;
  }
  .makeAContribution {
    background: url("../images/PWYC_Footer.webp") 50% 50% no-repeat !important;
    button {
      margin-top: 7em;
      margin-bottom: 5em;
    }
    .feelsGood {
      font-size: 19px;
      font-weight: 600;
      color: var(--fcc-blue);
      text-align: center;
      background-color: white;
      border: 1px spolid #777;
      border-radius: 55px/55px;
      height: 70px;
      line-height: 70px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
      margin-top: -35px;
    }
  }
  @media (min-width: 992px) {
    .sellBox {
      .sliderBox {
        margin-right: 5px;
      }
    }
    .yourSupport {
      img {
        padding-left: 1em;
      }
    }
    .paidFeatures {
      img {
        padding-right: 1em;
      }
    }
  }
  @media (max-width: 991px) {
    .lead {
      font-size: 18px;
    }
    section {
      padding-top: 2em;
      margin-bottom: 3em;
      img {
        margin-bottom: 2em;
      }
    }
    .sellBox {
      padding: 3em 0;
      .lead {
        font-size: 18px;
      }
      .sliderBox {
        margin-bottom: 2em;
      }
    }
    .freeFeatures {
      padding: 1em;
      h2.large {
        font-size: 2em;
        margin-top: 0.6em;
      }
    }
    .makeAContribution {
      button {
        margin-top: 6em;
        margin-bottom: 4em;
      }
    }
  }
}
