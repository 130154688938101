.aboutusPage {
  h1 {
    font-size: 40px;
    font-weight: 700;
    color: var(--fcc-blue);
    margin-bottom: 0.8em;
  }
  h2 {
    color: var(--fcc-blue);
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 0.8em;
  }
  h3 {
    margin-top: 0;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 4em;
    color: var(--fcc-blue);
    line-height: 28px;
  }
  section {
    color: var(--fcc-blue);
    margin-bottom: 5em;
  }
  .ourTeam {
    background-color: #D8EFFF;
    color: var(--fcc-gray);
    margin-bottom: 6em;
    padding: 4em 0 0 0;
    h2 {
      text-align: center;
      color: var(--fcc-gray);
    }
    h3 {
      color: var(--fcc-gray);
    }
    .featureBox {
      text-align: center;
      margin-bottom: 4em;
      img {
        border-radius: 8px;
        margin-bottom: 10px;
      }
      h3 {
        color: #181E28;
        font-size: 24px;
        font-weight: 500;
        margin: 0 0 10px 0;
      }
      h4 {
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        margin: 0;
      }
    }
  }
  .joinUs {
    margin-bottom: 8em;
    h3 {
      margin-bottom: 3em;
    }
  }
  .awards {
    text-align: center;
    h2 {
      text-align: center;
    }
    img {
      margin: 0 23px 30px 23px;
    }
  }
  .startingNow {
    padding: 4em 0;
    background-color: var(--fcc-blue);
    margin-bottom: 0;
    h2 {
      text-align: center;
      color: var(--fcc-light-blue);
    }
    h3 {
      color: var(--fcc-light-blue);
    }
  }

  @media (min-width: 992px) {
    .header {
      margin-bottom: 7em;
    }
  }
  @media (max-width: 991px) {
    h1 {
      font-size: 30px;
      text-align: center;
      margin-bottom: 1.5em;
    }
    h2 {
      font-size: 30px;
      text-align: center;
    }
    section {
      margin-bottom: 3em;
    }
    .aboutus {
      img {
        margin-bottom: 1em;
      }
    }
    .ourMission {
      img {
        margin-bottom: 1em;
      }
    }
    .whatWeOffer {
      img {
        margin-bottom: 1em;
      }
    }
  }
}
