.pricingFree {
  background-color: var(--fcc-pricing-light-blue);
  padding: 5em 0;
  margin-bottom: 5em;
  .featureBox {
    height: 260px;
    color: #172b4d;
    background-color: white;
    border-radius: 12px;
    margin-bottom: 2em;
    padding: 20px;
    font-size: 16px;
    img {
      margin-bottom: 10px;
    }
    h4 {
      font-size: 20px;
      font-weight: 700;
      margin: 0 0 15px 0;
    }
  }
  @media (max-width: 767px) {
    .featureBox {
      height: unset;
    }
  }
}
