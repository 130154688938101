.additionalAddons {
  .featureBox {
    position: relative;
    color: var(--fcc-gray);
    font-size: 16px;
    background-color: var(--fcc-light-gray);
    padding: 20px 20px 130px 20px;
    border-radius: 12px;
    margin-bottom: 2em;
    p {
      margin-bottom: 10px;
    }
    img {
      margin-bottom: 10px;
    }
    h4 {
      font-size: 20px;
      font-weight: 700;
      margin: 0 0 15px 0;
    }
    .featurePrice {
      color: var(--fcc-pricing-gray);
      font-weight: 700;
    }
    .boxFooter {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0 20px 20px 20px;
    }
  }
}
@media (max-width: 767px) {
  .additionalAddons {
    .featureBox {
      padding-bottom: 10px;
      .boxFooter {
        padding: 10px 0;
        position: static;
      }
    }
  }
}
